import React, { useEffect, useState } from "react"
import Icon from '../../../../components/Icon';
import classNames from 'classnames'
import * as styles from './Rotate.module.less';
let num = 10;

const Rotate = () => {
  const handleHtml = () => {
    const list = [];
    for (let i = 0; i < 32; i++) {
      list.push(
        <div className={styles.rotateItem} style={{ transform: `rotate(${(i + 1) * 11.25}deg)` }} />
      );
    }
    return list;
  }

  return (
    <div className={styles.contant}>
      `<Icon type="iconzuanshisuyuan" className={styles.icon} />`
      <div className={styles.rotateBox}>
        {handleHtml()}
      </div>
    </div>
  )
}

export const RotateImg = () => {
  return (
    <div className={styles.rotate}>
      <Icon type="iconzuanshisuyuan" className={styles.rotateIcon} />
      <img className={styles.rotateImg} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/demo/Lark20210602-133444.png" alt=""/>
    </div>
  )
}

export default Rotate;